import React from "react";
import styled from "styled-components";
import CloseIcon from "../../src/img/close.svg"
const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: #ffffff;
  color: #1b1b1d;
  z-index: 1005;
  box-shadow: 0px 0px 48px 8px rgb(97 91 136 / 15%);
  display: flex;
`;

const TextArea = styled.div`
  margin: auto 10px auto auto;
  font-size: 15px;

  a {
    font-size: 15px;
  }

  @media (max-width: 1279px) {
    margin: auto 10px;
  }
`;

const Close = styled.img`
  height: 10px;
  width: 10px;
  cursor: pointer;
  margin: 15px 15px auto auto;
`;

const SubmitButton = styled.button`
  display: flex;
  overflow: hidden;
  background: rgb(204, 102, 102);
  margin: auto 10px;
  padding: 12px 12px;

  cursor: pointer;
  user-select: none;
  transition: all 150ms linear;
  text-align: center;
  white-space: nowrap;
  text-decoration: none !important;
  text-transform: none;
  text-transform: capitalize;

  color: #fff;
  border: 0 none;

  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  justify-content: center;
  align-items: center;
  height: 40px;
  flex: 0 0 100px;

  &:hover {
    transition: all 150ms linear;

    opacity: 0.85;
  }

  &:active {
    transition: all 150ms linear;
    opacity: 0.75;
  }
`;

const Policy = styled.a`
  margin: 0 5px;
  text-decoration: underline;
  font-size: 16px;
  color: #1b1b1d;
  cursor: pointer;
`;

export const Snackbar = ({ onClose }) => {
    return (
        <Wrapper>
            <TextArea>
                Our website uses cookies to provide services. By clicking "OK" you consent to our use of cookies in accordance with the terms of our
                <Policy href={"https://www.blokarchitekci.pl/privacy-policy/"}>Privacy Policy</Policy>
            </TextArea>
            <SubmitButton onClick={onClose}>OK</SubmitButton>
            <Close alt="close" src={CloseIcon} onClick={onClose} />
        </Wrapper>
    );
};
