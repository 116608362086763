import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";
import { GlobalStyles } from "./GlobalStyles";
import styled from "styled-components";
import DescBAFront from "../img/DescBAFront.svg"
import DescBABack from "../img/MobiBA.svg"
import DescBBHFront from "../img/DescBBHFront.svg"
import DescBBHBack from "../img/DescBBHBack.svg"
import DescBIFront from "../img/DescBIFront.svg"
import DescBIBack from "../img/DescBIBack.svg"
import MobiBA from "../img/MobiBA.svg"
import MobiBBH from "../img/MobiBBH.svg"
import MobiBI from "../img/MobiBI.svg"
import useSiteMetadata from "./SiteMetadata";
import { Snackbar } from "../components/Snackbar";

const WrapperEntryScreen = styled.div`
  color: black;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
`

const CardsContainer = styled.div`
  margin: 20vh auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 420px;
  width: 1260px;

  a {
    justify-self: center;
    align-self: center;
  }

  img {
    width: 420px;
    height: 420px;
  }

  @media (max-width: 1279px) {
    display: none;
  }
`;

const Card = styled.div`
  cursor: pointer;
  width: 420px;
  height: 420px;
  margin: 0 auto;

  &:hover > .front {
    transform: perspective(600px) rotateY(-180deg);
  }

  &:hover .back {
    transform: perspective(600px) rotateY(0deg);
  }
`;

const Front = styled.div`
  width: 420px;
  height: 420px;
  overflow: hidden;
  backface-visibility: hidden;
  position: absolute;
  transition: transform 0.5s linear;
`;

const Back = styled.div`
  width: 420px;
  height: 420px;
  overflow: hidden;
  backface-visibility: hidden;
  position: absolute;
  transition: transform 0.5s linear;
  transform: rotateY(180deg);
`;

const MainLink = styled.div`
  cursor: pointer;

  @media (max-width: 700px) {
    height: 200px;
  }
`

const LayoutPage = styled.div`
  width: 100%;
  overflow: hidden;
`

const MobileCards = styled.div`
  display: none;
  height: auto;
  width: 340px;
  margin: 0 auto;
  grid-template-columns: 1fr;
  

  @media (max-width: 1279px) {
    display: grid;
    width: auto;
    max-width: 420px;
  }

  @media (max-width: 700px) {
    display: grid;
    max-width: 400px;

    a {
      height: 200px;
    }
  }
`

const TemplateWrapper = ({ children, ...props }) => {
  const { title, description } = useSiteMetadata();
  const [language, setLanguage] = useState("pl");

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  useEffect(() => {
    setIsSnackbarOpen(localStorage.getItem("isSnackbarShown") !== "true");
  }, []);

  const [alreadyShowed, setAlreadyShowed] = useState(null);
  const [showPage, setShowPage] = useState(null);

  useEffect(() => {
    setLanguage(localStorage.getItem("language") || "pl");
    setAlreadyShowed(sessionStorage.getItem("initialShowed") || false);
  }, []);

  useEffect(() => {
    setShowPage(alreadyShowed === "true");
  }, [alreadyShowed]);
  const showWholePage = () => {
    window.sessionStorage.setItem("initialShowed", "true");
    setShowPage(true);
  };

  const handleLanguageClick = () => {
    if (language === "pl") {
      setLanguage("eng");
    } else {
      setLanguage("pl");
    }
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
    localStorage.setItem("isSnackbarShown", "true");
  };

  const LayoutContainer = styled.div`
    position: relative;
  `;
  const renderContent = () => (
    <>
      <GlobalStyles />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <title>{title}</title>
        <meta name="description" content="Our studio was established in Cracow with joined forces of a group of friends - creative architects with Polish and Danish experience. The architecture and methodology of work in Blok reflect our Polish-Scandinavian design roots. Sharing the same values and approach to design, we created a place for affordable and modern architecture inspired by the Scandinavian style."/>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:description" content="Our studio was established in Cracow with joined forces of a group of friends - creative architects with Polish and Danish experience. The architecture and methodology of work in Blok reflect our Polish-Scandinavian design roots. Sharing the same values and approach to design, we created a place for affordable and modern architecture inspired by the Scandinavian style."/>
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.png`}
        />
      </Helmet>
    </>
  );
  const renderLinks = () => (
      <WrapperEntryScreen>
        <CardsContainer>
          <MainLink>
            <a href="https://blokbimhub.com/">
              <Card>
                <Front className="front">
                  <img src={DescBBHFront}/>
                </Front>

                <Back className="back">
                  <img src={DescBBHBack}/>
                </Back>
              </Card>
            </a>
          </MainLink>
          <MainLink>
            <Card className="card" onClick={showWholePage}>
              <Front className="front">
                <img src={DescBAFront}/>
              </Front>

              <Back className="back">
                <img src={DescBABack}/>
              </Back>
            </Card>
          </MainLink>
          <MainLink>
            <a href="https://www.blokarchitekci.pl/projects">
              <Card>
                <Front className="front">
                  <img src={DescBIFront}/>
                </Front>

                <Back className="back">
                  <img src={DescBIBack}/>
                </Back>
              </Card>
            </a>
          </MainLink>
        </CardsContainer>
        <MobileCards>
          <a href="https://blokbimhub.com/">
            <img src={MobiBBH}/>
          </a>
          <MainLink onClick={showWholePage}>
            <img src={MobiBA}/>
          </MainLink>
          <a href="https://www.blokarchitekci.pl/projects">
            <img src={MobiBI}/>
          </a>
        </MobileCards>
      </WrapperEntryScreen>
  );

  return (
    <LayoutPage>
      {showPage === true && (
        <LayoutContainer>
          {renderContent()}
          {isSnackbarOpen && <Snackbar onClose={handleSnackbarClose} />}
          <div>{children}</div>
        </LayoutContainer>
      )}
      {((showPage === null && alreadyShowed === null) ||
        (showPage === false && alreadyShowed === "true")) && (
        <>
          <LayoutContainer>{renderContent()}</LayoutContainer>
        </>
      )}
      {showPage === false && alreadyShowed !== "true" && renderLinks()}
    </LayoutPage>
  );
};

export default TemplateWrapper;
