import styled, { createGlobalStyle } from "styled-components";


export const colors = {
        white: "#ffffff",
        black: "#1B1B1D",
        fuzzyWuzzy: "#CC6666",
        ruddyPink: "#E58888",
        mauvelous: "#F4A6A6",
}

export const GlobalStyles = createGlobalStyle`
        body {
                margin: 0;
                padding: 0;
                background: ${colors.white};
                color: ${colors.black};
                font-family: "Montserrat", sans-serif;
                white-space: pre-wrap;
                overflow-x: hidden;
                width: 100%;
        }
        canvas {
                position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                background: ${colors.fuzzyWuzzy};
        }
        h2 {
                font-size: 96px;
                margin: 0;
        }
        h3 {
                font-size: 44px;
                margin: 0;
        }
        h4 {
                font-size: 24px;
                margin: 0;
                font-weight: 600;
        }

        a {
                font-size: 24px;
                margin: 0;
        }
        p {
                font-family: "Roboto", sans-serif;
                font-size: 18px;
                line-height: 26px;
                margin: 0;
        }

        @media (max-width: 1279px) {
                h2 {
                        font-size: 70px;
                }

                h3 {
                        font-size: 30px;
                }
        }
`

export const Wrapper = styled.div`
        margin: 250px auto 0;
        padding: 0 70px;
        max-width: 1140px;
        width: auto;
        align-items: center;

        @media (max-width: 700px) {
                padding: 0 30px;
                max-width: 100%;
        }
        
`;